<template>
  <div class="Page">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      effect="dark">
    </el-alert>
    <el-alert v-if="successMsg"
      :title="successMsg"
      type="success"
      effect="dark">
    </el-alert>
    <div class="opt-bar flex">
      <el-button @click="handleShowUploadBar" size="small" v-ripple>上传到公众号</el-button>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont iconrefresh"></i></el-button>
      <el-button @click="onBtnDeleteClick" type="danger" size="mini"><i class="iconfont iconclose"></i>删除</el-button>
    </div>
    <div class="upload-bar" v-if="uploadBarVisiable">
      <el-upload
        class="imgList"
        ref="upload"
        :limit="10"
        :action="uploadUrl"
        :headers="uploadHeaders"
        :auto-upload="true"
        :multiple="true"
        :on-progress="uploadProgress"
        :before-upload="uploadBefore"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        accept="image/png, image/jpeg, image/png"
        list-type="picture-card"
        :file-list="fileList"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
            >
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disableDelete"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="disableDelete"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
      </el-upload>
      <el-dialog :visible.sync="dialogPreviewVisible">
        <img width="100%" :src="dialogPreviewImageUrl" alt="">
      </el-dialog>
    </div>    
    <div class="grid-area" :style="'height:'+ tableHeight +'px;'">
      <div class="grid-img" v-for="(item, idx) in page.list" :key="idx"
        @mouseenter="mouseEnter(item)"
        @mouseleave="mouseLeave(item)"
      >
        <div class="image-opt">
          <el-checkbox @change="onItemCheckChange(item)" v-if="selectImageFlag || item.checked || (current && current.media_id==item.media_id)" class="checked" 
            v-model="item.checked"></el-checkbox>
          <icon-btn v-if="current && current.media_id==item.media_id" @click="onItemDeleteClick(item)" class="image-delete" icon="iconclose" size="small"></icon-btn>
        </div>
        <el-image class="el-image" :src="item.url" fit="contain" 
          referrer-policy="no-referrer"
          :preview-src-list="imagePreviewList"
        ></el-image>
      </div>
    </div>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>

  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {   IconBtn  },
  data() {
    return {
      errorMsg: '',
      successMsg: '',
      tableHeight: 560,
      current: false,
      checkList: [],
      selectImageFlag: false,
      mediaType: 'image',
      mediaTypeList: ["image","video", "voice", "news"],
      page: {pg: 1, list:[], total: 0, pageSize: 20, currentPage: 1, pageCount: 1},
      queryTime: 0,
      imagePreviewList: [],
      imghome: '',
      disableDelete: false,
      fileList: [],
      uploadHeaders: {},
      uploadUrl: '#',
      uploadBarVisiable: false,
      dialogPreviewVisible: false,
      dialogPreviewImageUrl: '',
    };
  },
  created (opt) {
    this.uploadUrl = this.global.api + '/api/media/uploadToMp'
    this.imghome = this.global.imghome
    this.resize();
    window.onresize = this.resize();
    this.onCurrentPageChange(1)
    this.uploadHeaders = {
      Authorization: this.global.token,
      platid: this.global.login.platid
    }
  },
  methods: {
    uploadBefore() {
      this.process(true);
      this.uploadHeaders = {
        Authorization: this.global.token,
        platid: this.global.login.platid
      }
      // console.log(this.uploadHeaders)
    },
    uploadProgress(e, file, fileList) {
      console.log(e);
    },
    uploadSuccess(res, file, fileList) {
      this.onCurrentPageChange(1);
      this.$refs.upload.clearFiles();
      this.uploadBarVisiable = false;
    },
    uploadError(e) {
      this.$notify.error('错误：' + e.status);
      this.process(false);
    },
    handleDownload(file) {

    },
    handleRemove(file) {

    },
    handlePictureCardPreview(file) {
      console.log(file)
    },
    handleShowUploadBar(){
      this.uploadBarVisiable = !this.uploadBarVisiable
      this.tableHeight = this.tableHeight - (this.uploadBarVisiable ? 1 : -1) * 148;
      console.log(this.tableHeight)
    },
    handleDelete(idx, row) {

    },
    handleEdit(idx, row) {

    },
    onBtnDeleteClick() {
      if (this.checkList.length == 0) {
        this.toast('请先选择要删除的内容');
        return;
      }
      var msg = `确认删除所选中的${this.checkList.length}条内容？`;
      this.$confirm(msg, '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.toast('待实现~')
        // this.deleteMulti();
      }).catch(()=>{
        this.$notify.info('取消删除~');
      })
    },
    deleteMulti() {
      if (this.checkList.length == 0) {
        return;
      }
      var len = this.checkList.length;
      var ob = this.checkList[len - 1];
      var pm = {
        id: ob.id
      }
      // this.post('/api/media/delete', pm, res=>{
      //   if (res.code == 0) {
      //     var idx = this.util.indexOf(this.page.list, ob.id);
      //     if (idx >= 0) {
      //       this.page.list.splice(idx, 1);
      //       this.page.total --;
      //     }
      //     this.checkList.splice(len-1, 1);
      //     this.$nextTick(()=>{
      //       this.deleteMulti()
      //     })            
      //   } else {
      //     this.$notify.error('删除失败：' + res.msg)
      //   }
      // })     
    },
    onItemDeleteClick(ob) {
      this.$confirm('确定删除该文件？', '确认')
      .then(()=>{
        this.toast('待实现~')
        // var pm = {
        //   id: ob.id
        // }
        // this.post('/api/media/delete', pm, res=>{
        //   if (res.code == 0) {
        //     this.$notify.success('删除成功~');
        //     var idx = this.util.indexOf(this.page.list, ob.id);
        //     if (idx >= 0) {
        //       this.page.list.splice(idx, 1);
        //       this.page.total --;
        //     }
        //     this.refresh();
        //   } else {
        //     this.$notify.error('删除失败：' + res.msg)
        //   }
        // })
      }).catch(()=>{

      })      
    },
    onItemCheckChange(item) {
      if (item.checked) {
        this.checkList.push(item);
      } else {
        var idx = this.util.indexOf(this.checkList, item);
        if (idx > -1) {
          this.checkList.splice(idx, 1);
        }
      }
    },
    mouseLeave(row) {
      this.current = {}
    },
    mouseEnter(row) {
      this.current = row;
      // this.refresh();
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        platid: this.global.login.platid,
        pg : pg,
        // pageSize: this.page.pageSize,
        // skey: this.skey,
        type: this.mediaType
      }
      this.post('api/media/batchget_material', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data;
        this.imagePreviewList = []
        for(var i=0; i<this.page.list.length; i++) {
          var ob = this.page.list[i]
          // if (i==0) console.log(ob);
          var url = ob.url
          this.imagePreviewList.push(url);
        }
      })
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
  } //methods
}
</script>

<style scoped lang="scss">
.Page {
 margin-top: 0px;

  .upload-bar {
    text-align: left;
  }
  .grid-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-flow: flex-wrap;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: auto;

    .grid-img {
      margin: 5px;
      box-shadow: 0 0 3px $dark;
      width: 180px;
      height: 210px;
      border: 1px solid #ebebeb;
      box-shadow: 0 0 5px #cbcbcb;
      border-radius: 5px;
      background-color: #cbcbcb;
    }

    .el-image {
      width: 160px;
      height: 150px;
    }
  }
}

.image-opt {
  height: 32px;
}
.image-delete {
  float: right;
  position: relative;
  margin-left: 130px;
}

.image-opt {
  text-align: left;

  .checked {
    margin: 5px;
  }
  .selectImage_true{
    background-color: $primary;
    color: #fff;
  }
}

</style>
